const formData = {
    "Classic": {
        operatingSystems: [
            "Windows"
        ],

        licenses: {
            "Ad-Free": {
                "supportIssues": [
                    "Activation",
                    "Connection Issue",
                    "Download Issue",
                    "Installation Issue",
                    "License Key",
                    "Purchase Issue",
                    "Subscription Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],

                showOrderReference: true
            },


            "Pro": {
                "supportIssues": [
                    "Activation",
                    "Antivirus Feature",
                    "Connection Issue",
                    "Download Issue",
                    "Installation Issue",
                    "License Key",
                    "Purchase Issue",
                    "Subscription Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],
                showOrderReference: true
            },


            "Pro + VPN": {
                "supportIssues": [
                    "Activation",
                    "Antivirus Feature",
                    "Connection Issue",
                    "Cyberghost Subscription",
                    "Download Issue",
                    "Installation Issue",
                    "License Key",
                    "Purchase Issue",
                    "Subscription Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],
                showOrderReference: true
            },


            "Free user": {
                "supportIssues": [
                    "Connection Issue",
                    "Download Issue",
                    "Installation Issue",
                    "Purchase Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],
                showOrderReference: false
            },
        },


    },


    "Web": {
        operatingSystems: [
            "Windows",
            "MacOS"
        ],
        licenses: {
            "Ad-Free": {
                "supportIssues": [
                    "Activation",
                    "Connection Issue",
                    "Download Issue",
                    "Installation Issue",
                    "License Key",
                    "Purchase Issue",
                    "Subscription Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],
                showOrderReference: true
            },


            "Pro": {
                "supportIssues": [
                    "Activation",
                    "Antivirus Feature",
                    "Connection Issue",
                    "Download Issue",
                    "Installation Issue",
                    "License Key",
                    "Purchase Issue",
                    "Subscription Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],
                showOrderReference: true
            },


            "Pro + VPN": {
                "supportIssues": [
                    "Activation",
                    "Antivirus Feature",
                    "Connection Issue",
                    "Cyberghost Subscription",
                    "Download Issue",
                    "Installation Issue",
                    "License Key",
                    "Purchase Issue",
                    "Subscription Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],
                showOrderReference: true
            },


            "Free user": {
                "supportIssues": [
                    "Connection Issue",
                    "Download Issue",
                    "Installation Issue",
                    "Purchase Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],
                showOrderReference: false
            },

        },

    },


    "Mobile": {
        operatingSystems: [
            "Android"
        ],

        licenses: {
            "Pro": {
                "supportIssues": [
                    "Activation",
                    "Connection Issue",
                    "Download Issue",
                    "Installation Issue",
                    "Purchase Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],
                showOrderReference: true
            },

            "Free user": {
                "supportIssues": [
                    "Connection Issue",
                    "Download Issue",
                    "Installation Issue",
                    "Purchase Issue",
                    "Technical Issue",
                    "Torrenting Issue",
                    "Uninstall/Reinstall",
                    "Other",
                ],
                showOrderReference: false
            }
        }

    },


    "Speed": {
        operatingSystems: [
            "Windows",
            "MacOS"
        ],
        licenses: {
            "N/A": {
                "supportIssues": [
                    "Wallet Assistance",
                    "Transaction Issue",
                    "BTT Earnings",
                    "Other",
                ],

                showOrderReference: true,
                isWalletAddress: true
            }
        }

    },


    "File System": {
        operatingSystems: [
            "Windows",
            "MacOS"
        ],

        licenses: {
            "N/A": {
                "supportIssues": [
                    "BTT Earnings",
                    "Other",
                ],
                showOrderReference: false
            }
        }
    },

    "Lite": {
        operatingSystems: [
            "Windows",
            "Android",
            "MacOS",
            "IOS",
            "Ipad",
            "Linux",
            "Other"
        ],
        browser: [
            "Chrome",
            "Firefox",
            "Safari",
            "Edge",
            "Opera",
            "Brave",
            "Internet Explorer",
            "Other"
        ],
        licenses: {
            "Free user": {
                "supportIssues": [
                    // "Playback Issue",
                    // "Tracker Connection",
                    "Connection Issue",
                    "Technical Issue",
                    "Other",
                ],
                showOrderReference: true
            }
        }
    }
}

const products = [
    "BitTorrent Classic",
    "BitTorrent Web",
    "BitTorrent Mobile",
    "BitTorrent Speed",
    "BitTorrent File System",
    "uTorrent Classic",
    "uTorrent Web",
    "uTorrent Mobile",
    "uTorrent Lite",
]
exports.formData = formData
exports.products = products