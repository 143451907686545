import React, {useState, useEffect} from "react"
import {Col, Input, Spinner} from "reactstrap";
import HelpCenterSearch from "../../components/help-center/helpCenterSearch";
import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import {Link, navigate} from "gatsby";
import HelpCenterLinks from "../../components/help-center/helpCenterLinks"
import EnvelopeIcon from "../../images/icons/envelope.svg"
import HelpCenterFormInfo from "../../components/help-center/helpCenterFormInfo";
import useTranslations from "../../utils/useTranslations";

export default ({pageContext: {text}}) => {
    console.log(text)
    const t = useTranslations(text)
    const formKeys = {
        FIRST_NAME: "firstName",
        LAST_NAME: "lastName",
        EMAIL: "email",
        OPERATING_SYSTEM: "os",
        PRODUCT: "product",
        ORDER_REFERENCE: "orderReferenceNumber",
        LICENSE: "license",
        SUBJECT: "subject",
        DESCRIPTION: "description",
        SUPPORT_ISSUE: "supportIssue",
        BROWSER: "browser",
        VERSION: "version",
    }

    const CAPTCHA = "captcha"
    const SUBMISSION = "submitError"
    const siteKey = "6LdYNSAiAAAAAK-VJu4PA19p_IEum2HSSD9wQuTs"

    const formData = HelpCenterFormInfo.formData;
    const formProducts = HelpCenterFormInfo.products;


    const [files, setFiles] = useState([]);
    let fields = {};
    for (let key in formKeys) {
        let formItemKey = formKeys[key];
        fields[formItemKey] = ""
    }
    fields.files = "";

    const [userInput, updateUserInput] = useState(fields);
    const [captchaValidation, updateCaptcha] = useState(null);
    const [fieldErrors, updateErrorFields] = useState([]);

    const [isLoading, setLoading] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);


    const [loadUrlParams, setLoadUrlParams] = useState(false);
    useEffect(()=>{
        if(!loadUrlParams){
            setUrlParameters();
        }
    })


    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        updateUserInput(values => ({...values, [name]: value}))
        if(value === "uTorrent Lite") {
            updateUserInput(values => ({...values, [formKeys.LICENSE]: "Free user"}))
        }
    }

    function cancelClick() {
        navigate(-1)
    }

    function validate() {
        setLoading(true)

        let errors = [];

        if (userInput[formKeys.OPERATING_SYSTEM] === "Operating System*") {
            errors.push(formKeys.OPERATING_SYSTEM);
        }
        if (userInput[formKeys.PRODUCT] === "Product*") {
            errors.push(formKeys.PRODUCT);
        } else {
            if (userInput[formKeys.PRODUCT] === "uTorrent Lite" && userInput[formKeys.BROWSER] === "Browser*") {
                errors.push(formKeys.BROWSER);
            }
        }
        if (userInput[formKeys.LICENSE] === "License Type*") {
            errors.push(formKeys.LICENSE);
        }
        if (userInput[formKeys.SUPPORT_ISSUE] === "Support Issue*") {
            errors.push(formKeys.SUPPORT_ISSUE);
        }
        if(!isEmailValid()){
            errors.push(formKeys.EMAIL);
        }


        let requiredFields = [
            formKeys.FIRST_NAME,
            formKeys.LAST_NAME,
            formKeys.SUBJECT,
            formKeys.DESCRIPTION,

            formKeys.OPERATING_SYSTEM,
            formKeys.PRODUCT,
            formKeys.LICENSE,
            formKeys.SUPPORT_ISSUE,
        ]
        for (let key of requiredFields) {
            if (userInput[key].length < 1) {
                errors.push(key);
            }
        }


        if (captchaValidation === null) {
            errors.push(CAPTCHA)
        }
        updateErrorFields(errors);

        if (errors.length === 0) {
            postTicket();
        }else{
            setLoading(false);
        }
    }

    function isEmailValid(){
        return (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(userInput[formKeys.EMAIL]));
    }

    function isUserValidated(){
        let user = sessionStorage.getItem(HelpCenterLinks.USER_INFO)
        return user !== null && user !== "";
    }

    function postTicket() {
        let form = new FormData();
        form.append("email",userInput[formKeys.EMAIL])
        form.append("subject",userInput[formKeys.SUBJECT])
        form.append("description",userInput[formKeys.DESCRIPTION])
        form.append("status","2")
        form.append("priority","1")

        form.append("custom_fields[cf_your_name]",userInput[formKeys.FIRST_NAME] + " " + userInput[formKeys.LAST_NAME])
        form.append("custom_fields[cf_operating_system]", userInput[formKeys.OPERATING_SYSTEM])
        form.append("custom_fields[cf_brand]", userInput[formKeys.PRODUCT])
        form.append("custom_fields[cf_support_issue]", userInput[formKeys.SUPPORT_ISSUE])
        if(userInput[formKeys.LICENSE] !== "N/A"){
            form.append("custom_fields[cf_license]", userInput[formKeys.LICENSE])
        }
        form.append("custom_fields[cf_order_reference_number]", userInput[formKeys.ORDER_REFERENCE])
        form.append("custom_fields[cf_user_status]",isUserValidated()+"")

        form.append("custom_fields[cf_browser]",userInput[formKeys.BROWSER])
        form.append("custom_fields[cf_version]",userInput[formKeys.VERSION]);
        form.append("custom_fields[cf_user_agent]",navigator.userAgent);


        for(let file of files){
            form.append("attachments[]",file)
        }

        Axios.post("https://5tfj2zmxq2.execute-api.us-east-1.amazonaws.com/bt-help-center/tickets", form)
            .then((res) => {
                setShowThankYou(true)
            })
            .catch((err) => {
                updateErrorFields([...fieldErrors,SUBMISSION])
            }).finally(() => {
                setLoading(false)
            })
    }

    function filesChanged(event){
        const inputtedFiles = event.target.files;
        const value = event.target.value;

        let MAX_SIZE = 1024 * 1024 * 4;
        let count = 0;
        for(let file of inputtedFiles){
            count += file.size;
        }
        if(count <= MAX_SIZE){
            setFiles( inputtedFiles);
            updateUserInput(values => ({...values, "files": value}))

        }else{
            updateUserInput(values => ({...values, "files": ""}))
        }
    }

    function captchaChange(event) {
        if (event === null) {
            updateErrorFields([...fieldErrors, CAPTCHA])
        }else{
            let fields = [];
            fieldErrors.forEach(field =>{
                if(field !== CAPTCHA){
                    fields.push(field);
                }
            });

            updateErrorFields(fields)
        }
        updateCaptcha(event)
    }

    function getDropDownProducts(){
        let items = [];

        for(let product of formProducts){
            items.push((<option key={product} >{product}</option>))
        }

        return items
    }


    function getSelectedProduct(){
        let key = userInput[formKeys.PRODUCT];
        if(key !== null ) {
            let product = key.split(" ").splice(1).join(" ");

            if (formData[product]) {
                return formData[product]
            }
        }
            return null
    }

    function getSelectedProductLicenses(){
        if(getSelectedProduct() !== null){
            return getSelectedProduct()['licenses'][userInput[formKeys.LICENSE]]
        }else{
            return null
        }
    }


    function setUrlParameters(){
        let urlSearchParams = new URLSearchParams(document.location.search);

        let product = urlSearchParams.get("product")
        let version = urlSearchParams.get("version")
        let browser = urlSearchParams.get("browser")
        let os = (urlSearchParams.get("os")+"").replace(" ", "");
        let email = urlSearchParams.get("email");


        let urlParams = {}

        if(product != null){
            urlParams[formKeys.PRODUCT] = product;
            if(product === "uTorrent Lite") {
                urlParams[formKeys.LICENSE] = "Free user";
            }
        }
        if(version != null){
            urlParams[formKeys.VERSION] = version;
        }
        if(browser != null){
            urlParams[formKeys.BROWSER] = browser;
        }
        if(os.length > 0){
            urlParams[formKeys.OPERATING_SYSTEM] = os;
        }
        if(email != null){
            urlParams[formKeys.EMAIL] = email;
        }

        updateUserInput(values => (
            {
                ...values,
                ...urlParams
            }))

        setLoadUrlParams(true)
    }

    return (
        <>
            <div className="help-center-submit-ticket">
                <HelpCenterSearch text={text}/>

                {showThankYou && <div className="submit-thank-you">
                    <div className="thank-you-body">
                        <div className="close" onClick={()=>navigate(HelpCenterLinks.homeLink)}>
                            X
                        </div>

                        <div className="thank-you-content">
                            <div className="envelope-icon-holder">
                                <img src={EnvelopeIcon}/>
                            </div>

                            <div className="thank-you">
                                {t("ticketThankYou")}
                            </div>
                            <div className="reply">
                                {t("ticketGetBackToYou")}
                            </div>
                        </div>

                    </div>

                </div>}

                <div className="main-content">

                    <div className="body">
                        <h1>{t("ticketHeader")}</h1>
                        <div className="pb-3 pt-2">
                            {t("ticketPrefaceLine1", {link:<Link to={HelpCenterLinks.homeLink}>{t("ticketHelpCenterLink")}</Link>})}
                            <div className="divider-line"/>
                            {t("ticketPrefaceLine2")}
                            <div className="divider" />
                            <br/>
                            <ul>

                            <li className="bullet-text">
                                {t("ticketPrefaceLine3")}
                            </li>
                            <li className="bullet-text">
                                {t("ticketPrefaceLine4")}
                            </li>
                            </ul>
                        </div>

                        <div className="input-container">
                            <label
                                className={!fieldErrors.includes(formKeys.FIRST_NAME) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                            <input inputMode="text"
                                   className={fieldErrors.includes(formKeys.FIRST_NAME) ? "error" : ""}
                                   name={formKeys.FIRST_NAME}
                                   value={userInput[formKeys.FIRST_NAME]}
                                   onChange={handleChange}
                                   placeholder={t("ticketFirstName")} />
                        </div>
                        <div className="input-container">
                            <label
                                className={!fieldErrors.includes(formKeys.LAST_NAME) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                            <input inputMode="text"
                                   className={fieldErrors.includes(formKeys.LAST_NAME) ? "error" : ""}
                                   name={formKeys.LAST_NAME}
                                   value={userInput[formKeys.LAST_NAME]}
                                   onChange={handleChange}
                                   placeholder={t("ticketLastName")} />
                        </div>
                        <div className="input-container">
                            <label
                                className={!fieldErrors.includes(formKeys.EMAIL) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                            <input inputMode="email"
                                   className={fieldErrors.includes(formKeys.EMAIL) ? "error" : ""}
                                   name={formKeys.EMAIL}
                                   value={userInput[formKeys.EMAIL]}
                                   onChange={handleChange}
                                   placeholder={t("ticketEmail")} />
                        </div>

                        <div className="input-container dropdown-container">
                            <div>
                                <label className={!fieldErrors.includes(formKeys.PRODUCT) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                                <Input type="select"
                                       name={formKeys.PRODUCT}
                                       className={fieldErrors.includes(formKeys.PRODUCT) ? "error" : ""}
                                       value={userInput[formKeys.PRODUCT]}
                                       onChange={handleChange}>
                                    <option key={"product"}>{t("ticketProduct")}</option>

                                    {getDropDownProducts()}

                                </Input>

                            </div>
                            {
                                userInput[formKeys.PRODUCT] === "uTorrent Lite" ? 
                                (<div>
                                    <label className={!fieldErrors.includes(formKeys.BROWSER) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                                    <Input type="select"
                                           name={formKeys.BROWSER}
                                           className={fieldErrors.includes(formKeys.BROWSER) ? "error" : ""}
                                           value={userInput[formKeys.BROWSER]}
                                           onChange={handleChange}>
                                        <option>Browser*</option>
                                        {getSelectedProduct() && getSelectedProduct()["browser"].map(browser => <option key={browser} value={browser}>{browser}</option>)}
    
                                    </Input>
                                </div>) :
                                (<div>
                                    <label className={!fieldErrors.includes(formKeys.LICENSE) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                                    <Input type="select"
                                           name={formKeys.LICENSE}
                                           className={fieldErrors.includes(formKeys.LICENSE) ? "error" : ""}
                                           value={userInput[formKeys.LICENSE]}
                                           onChange={handleChange}>
                                        <option>{t("ticketLicenseType")}</option>
                                        {getSelectedProduct() && Object.keys(getSelectedProduct()["licenses"]).map(license => <option key={license} value={license}>{t("license"+license)}</option>)}
    
                                    </Input>
                                </div>)
                            }
                        </div>


                        <div className="input-container dropdown-container">
                            <div>
                                <label
                                    className={!fieldErrors.includes(formKeys.OPERATING_SYSTEM) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                                <Input type="select"
                                       name={formKeys.OPERATING_SYSTEM}
                                       className={fieldErrors.includes(formKeys.OPERATING_SYSTEM) ? "error" : ""}
                                       value={userInput[formKeys.OPERATING_SYSTEM]}
                                       onChange={handleChange}>
                                    <option>
                                        {t("ticketOperatingSystem")}
                                    </option>
                                    {getSelectedProduct() && (getSelectedProduct()["operatingSystems"]).map(os => <option key={os} value={os}>{t("os"+os)}</option>)}
                                </Input>
                            </div>

                            <div>
                                <label className={!fieldErrors.includes(formKeys.SUPPORT_ISSUE) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                                <Input type="select"
                                       name={formKeys.SUPPORT_ISSUE}
                                       className={fieldErrors.includes(formKeys.SUPPORT_ISSUE) ? "error" : ""}
                                       value={userInput[formKeys.SUPPORT_ISSUE]}
                                       onChange={handleChange}>
                                    <option>{t("ticketSupportIssue")}</option>
                                    {getSelectedProductLicenses() && (getSelectedProductLicenses()["supportIssues"]).map(supportIssue => <option key={supportIssue} value={supportIssue}>{t("supportIssue"+supportIssue)}</option>)}
                                </Input>
                            </div>
                        </div>

                        {getSelectedProductLicenses() && getSelectedProductLicenses()["showOrderReference"] && <div className="input-container">
                            <label
                                className={!fieldErrors.includes(formKeys.ORDER_REFERENCE) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                            <input inputMode="text"
                                   className={fieldErrors.includes(formKeys.ORDER_REFERENCE) ? "error" : ""}
                                   name={formKeys.ORDER_REFERENCE}
                                   value={userInput[formKeys.ORDER_REFERENCE]}
                                   onChange={handleChange}
                                   placeholder={getSelectedProductLicenses()["isWalletAddress"] ? t("ticketWalletAddress") : t("ticketOrderReference") }/>
                        </div>}
                        <div className="input-container">
                            <label
                                className={!fieldErrors.includes(formKeys.SUBJECT) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                            <input inputMode="text"
                                   className={fieldErrors.includes(formKeys.SUBJECT) ? "error" : ""}
                                   name={formKeys.SUBJECT}
                                   value={userInput[formKeys.subject]}
                                   onChange={handleChange}
                                   placeholder={t("ticketSubject")} />
                        </div>
                        <div className="input-container">
                            <label className={!fieldErrors.includes(formKeys.DESCRIPTION) ? "hide-label" : ""}>{t("ticketFieldRequired")}</label>
                            <textarea inputMode="text"
                                      className={fieldErrors.includes(formKeys.DESCRIPTION) ? "error" : ""}
                                      name={formKeys.DESCRIPTION}
                                      value={userInput[formKeys.description]}
                                      onChange={handleChange}
                                      rows={7} placeholder={t("ticketDescription")} />
                        </div>

                        <div className="input-container">
                            <div className="file-holder">
                                <input className={"file-picker"} type="file" accept="image/png, image/jpeg" multiple
                                       onChange={filesChanged} value={userInput.files}  placeholder={"Max Size"}/>
                                <div className={"text-size-12 pt-2"}>{t("ticketFileSizeMax")}</div>
                            </div>
                        </div>

                        <div className="input-container">
                            <label className={!fieldErrors.includes(CAPTCHA) ? "hide-label" : ""}>{t("ticketValidCaptcha")}</label>

                            <ReCAPTCHA
                                sitekey={siteKey}
                                onChange={captchaChange} />
                        </div>

                        <div className="d-inline-flex mt-3">
                            <div className=" submit-button" onClick={validate}>
                                {isLoading && <Spinner className={"spinner"}/>}
                                {!isLoading && <>{t("ticketSubmit")}</>}
                            </div>
                            <div className="cancel-button" onClick={cancelClick}>
                                {t("ticketCancel")}
                            </div>
                        </div>

                        <div className="input-container pt-2">
                            <label className={!fieldErrors.includes(SUBMISSION) ? "hide-label" : ""}>{t("ticketError")}</label>
                        </div>
                    </div>


                </div>

            </div>

    </>
    )
}